





























import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import TRAILER from '@/graphql/queries/Trailer.graphql';
import TRAILER_CREATE from '@/graphql/mutations/TrailerCreate.graphql';
import TRAILER_UPDATE from '@/graphql/mutations/TrailerUpdate.graphql';
import { required } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { CODES } from '@/utils/constants';
import {
  Trailer,
  TrailerCreateInput,
  TrailerCreateMutationVariables,
  TrailerQueryVariables,
  TrailerUpdateMutationVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AdminTrailersAdd',
  components: {
    AppMain,
    AppForm,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        name: '',
      } as TrailerCreateInput,
      trailer: {} as Trailer,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  methods: {
    validationMessages,
    fillForm(trailer: Trailer) {
      if (!trailer) return;
      this.form = {
        name: trailer.name || '',
      };
    },
    async saveTrailer() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.$route.params.id) await this.updateTrailer();
        else await this.addTrailer();
        this.$router.replace({ name: 'admin-trailers' });
        this.$notify({
          text: 'Trailer saved!',
          type: 'success',
          duration: 6000,
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    addTrailer() {
      return this.$apollo.mutate({
        mutation: TRAILER_CREATE,
        variables: {
          input: {
            name: this.form.name,
          },
        } as TrailerCreateMutationVariables,
      });
    },
    updateTrailer() {
      return this.$apollo.mutate({
        mutation: TRAILER_UPDATE,
        variables: {
          input: {
            id: this.trailer.id,
            name: this.form.name,
          },
        } as TrailerUpdateMutationVariables,
      });
    },
  },
  apollo: {
    trailer: {
      query: TRAILER,
      variables(): TrailerQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ trailer: Trailer }>) {
        if (data) this.fillForm(data.trailer);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-trailers' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
    },
  },
});
